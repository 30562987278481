/* From uiverse.io by @G4b413l */
.ball {
    position: relative;
    bottom: 50px;
    left: calc(100% - 20px);
    width: 50px;
    height: 50px;
    background: rgb(77, 3, 3);
    border-radius: 50%;
    animation: ball-move8234 3s ease-in-out 1s infinite alternate;
}

.ball::after {
    position: absolute;
    content: '';
    top: 25px;
    right: 5px;
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
}

.bar {
   
    width: 500px;
    height: 18.5px;
    background: #202C45;
    border-radius: 30px;
    transform: rotate(-15deg);
    animation: up-down6123 3s ease-in-out 1s infinite alternate;
}
@media screen  and (max-width:567px){
    .bar {
            width: 300px;
            height: 18.5px;
            background: #202C45;
            border-radius: 30px;
            transform: rotate(-15deg);
            animation: up-down6123 3s ease-in-out 1s infinite alternate;
        }
}

@keyframes up-down6123 {
    from {
        transform: rotate(-15deg);
    }

    to {
        transform: rotate(15deg);
    }
}

@keyframes ball-move8234 {
    from {
        left: calc(100% - 40px);
        transform: rotate(360deg);
    }

    to {
        left: calc(0% - 20px);
        transform: rotate(0deg);
    }
}