.product{
    min-height: 500px;

}

.product .card{
    margin-top: 25px;
}
.product a {
    text-decoration: none;
}
.product img{
    width: 500px;
    height: 300px;
}
.product .card-body{
    max-height: 20vh;
}

.cards-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding: 20px;
    border-top: 1px solid #ddd;
}
.card-img-top{
    position: relative;
}
.outStock{
    position: absolute;
    top: 20px;
    left: 10px;
}
.outStock-text{
    background-color: #e20a0a;
    color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.outStock-text h3{
    font-size: 13px !important;
    text-align: center;
    font-weight: 600;
    padding: 8px 20px;
}

/*Text*/
.text-title {
    font-weight: 900;
    font-size: 1.2em;
    color: #252525;
}

.text-body {
   font-size: 12px;
   color: #252525;
   font-weight: 300;
}

/*Button*/
.cards-button {
    border: 1px solid #252525;
    display: flex;
    padding: .3em;
    cursor: pointer;
    border-radius: 50px;
    transition: .3s ease-in-out;
}

/*Hover*/
.product .card:hover {
   transform: scale(1.06);
   transition: 0.5 ease all;
   box-shadow: rgba(226, 196, 63, 0.25) 0px 13px 47px -5px, rgba(180, 71, 71, 0.3) 0px 8px 16px -8px;
   -webkit-transform: scale(1.06);
   -moz-transform: scale(1.06);
   -ms-transform: scale(1.06);
   -o-transform: scale(1.06);
   -webkit-transition: 0.5 ease all;
   -moz-transition: 0.5 ease all;
   -ms-transition: 0.5 ease all;
   -o-transition: 0.5 ease all;
}

.cards-button:hover {
    border: 1px solid #ffcaa6;
    background-color: #ffcaa6;
}