.about-section{
    text-align: center;
    padding: 20px;
}
.about{
    background-color: rgba(247, 248, 248, 0.801);
    height: 100%;
    margin-bottom: -30px;
}
.about-section h3{
    color:rgb(3, 3, 46);
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 600;
}
.about p{
    color: black;
    line-height: 35px;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    text-align: center;
    padding-bottom: 20px;
}

/* .about-img img{
    width: 500px;
    height: 400px;
    object-fit: contain;
   
} */

