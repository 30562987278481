.permision{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
}
.background_image{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../Images/approved.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: grayscale(100);
    -webkit-filter: grayscale(100);
    z-index: -1;
}
.permision .welcome{
    width: 100vw;
    text-align: center;
}
.permision h3{
    font-size: 30px;
    font-weight: bold;
}
.permision .over h5{
    text-align: center;
    margin-top: 15px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.801);
    font-weight: bold;
}
.permision h3 span{
    display: block;
    text-transform: uppercase;
    color: #202C45;
    margin: 15px 0px;
    font-size: 40px;
}

.permision .buttons-com h5{
    font-size: 20px;
    font-weight: bold;
}
.permision .buttons-com .buttonssss{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.overButton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
}
.permision .over .overButton button {
   all: unset;
   background-color: #202C45;
   color: white;
   padding: 10px 55px;
   cursor: pointer;
   transition: 0.5s;
   border-radius: 50px;
   -webkit-border-radius: 50px;
   -moz-border-radius: 50px;
   -ms-border-radius: 50px;
   -o-border-radius: 50px;
   -webkit-transition: 0.5s;
   -moz-transition: 0.5s;
   -ms-transition: 0.5s;
   -o-transition: 0.5s;
}
.permision .over .overButton button:hover,
.permision .buttons-com button:hover
{
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.permision .buttons-com button{
    all: unset;
    cursor: pointer;
    padding: 8px 20px;
    border: 1px solid #202C45;
    transition: 0.5s;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}
.permision .buttons-com button:nth-of-type(1){
    background-color: #202C45;
    color: white;
    padding: 5px 38px;
    margin: 20px;
}
.permision .buttons-com button:nth-of-type(2){
    background-color: rgb(16, 16, 65);
    color: azure;
    padding: 5px 40px;
    margin: 20px;
}
@media screen and (max-width:600px){
    .permision .buttons-com button:nth-of-type(1) {
        margin: 20px 35px;
    }
    .permision .buttons-com button:nth-of-type(1) {
            margin: 20px 35px;
    }
    .permision h3{
        font-size: 20px;
    }
    .permision h3 span{
        font-size: 20px;
    }
    .permision .buttons-com h5 {
        font-size: 14px;
        font-weight: bold;
    }
}