#app {
    height: 100%
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}
section .back{
    background-color: black;
    height: 600px;
    width: 100vw;
    overflow: hidden;
}

.swiper {
    width: 100%;
    height: 80vh;
    
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    
    background-color: black;
    height: 600px;
    width: 100vw;
    overflow: hidden;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    position: absolute;
    width: 100%;
    height: 80vh;
    object-fit: cover;
    opacity: 0.3;
    
}

.swiper-slide .texts{
    position: absolute;
    max-width: 80%;
    margin: auto;
}
.swiper-slide .texts h4{
    color: #E81C2E;
    font-size: 40px;
    font-weight: 700;
    margin: 15px 0px;
}
.swiper-slide .texts h2{
    text-transform: capitalize;
    color: white;
    font-size: 50px;
    font-weight: bolder;
    margin: 15px 0px;
}
.swiper-slide .texts p {
    color: rgb(214, 9, 9);
    font-size: 30px;
    line-height: 16px;
    font-weight: 600;
    margin: 10px 0px;
}
.swiper-slide .texts button{
    border: none;
    text-transform: capitalize;
    outline:none;
    padding: 15px 35px;
    background-color: #E81C2E;
    color: white;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    margin: 10px 0px;
}
.swiper-slide .texts button:hover{
    color: #E81C2E;
    background-color: #202C45;
    box-shadow: inset 200px 0 0 0 #202C45;
    transition: ease-out 0.7s;
    border-radius: 60px;
    -webkit-transition: ease-out 0.7s;
    -moz-transition: ease-out 0.7s;
    -ms-transition: ease-out 0.7s;
    -o-transition: ease-out 0.7s;
}
@media screen and (max-width:760px){
.swiper-slide .texts h4 {
        font-size: 30px;
    }
    .swiper-slide .texts h2{
        font-size: 35px;
        color: white;
    }
    .swiper-slide .texts p{
        font-size: 18px;
        line-height: 16px;
    }
    
}