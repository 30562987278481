@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600;700&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
.shop .input {
  width: 60%;
  height: 50px;
  border: 2px solid transparent;
  outline: none;
  border-bottom: 2px solid #202C45;
  caret-color: #202C45;
  background-color: #202C45;
  padding: 8px;
  color: white;
  transition: .5s linear;
  font-family: monospace;
  margin-bottom: 12px;
  letter-spacing: 1px;
 
}

.shop .input:focus {
  border: 2px solid #202C45;
  caret-color: #202C45;
  color: #f0f2f7;
  box-shadow: 4px 4px 10px #070707;
}

.shop .input:focus::placeholder {
  color: white;
}
@media screen  and (max-width:567px){
  .shop .input{
    width: 100%;
  }
}